import React from "react";
import ResponsiveAppBar from "./responsive-app-bar";
import {Container, Typography} from "@mui/material";
import Spacer from "./Spacer";
import Footer from "./home/Footer";

function CompanyDissolved(){
    return (<>
        <ResponsiveAppBar showLogin={false}/>
        <Container maxWidth={"md"}>
            <Spacer height={50}/>
            <Typography align={"center"}>
                Per our records your company has been dissolved and does not need to file an Annual Report with the Department of State.
            </Typography>
            <Spacer height={50}/>
            <Typography align={"center"}>
                We remain at your disposal to handle your Annual Report filings with our directed service in the future via your company profile on our website.
            </Typography>
        </Container>
        <Spacer height={250}/>
        <Footer/>

    </>);
}

export default CompanyDissolved